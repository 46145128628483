/* eslint-disable @typescript-eslint/explicit-module-boundary-types -- all exports are ImporterLocale which is already fully typed */
export const enUS = {
    general: {
        goToPreviousStepTooltip: 'Go to previous step'
    },
    fileStep: {
        initialDragDropPrompt: 'Drag-and-drop CSV file here, or click to select in folder',
        activeDragDropPrompt: 'Drop CSV file here...',
        getImportError: (message) => `Import error: ${message}`,
        getDataFormatError: (message) => `Please check data formatting: ${message}`,
        goBackButton: 'Go Back',
        nextButton: 'Choose columns',
        rawFileContentsHeading: 'Raw File Contents',
        previewImportHeading: 'Preview Import',
        dataHasHeadersCheckbox: 'Data has headers',
        previewLoadingStatus: 'Loading preview...'
    },
    fieldsStep: {
        stepSubtitle: 'Select Columns',
        requiredFieldsError: 'Please assign all required fields',
        nextButton: 'Import',
        dragSourceAreaCaption: 'Columns to import',
        getDragSourcePageIndicator: (currentPage, pageCount) => `Page ${currentPage} of ${pageCount}`,
        getDragSourceActiveStatus: (columnCode) => `Assigning column ${columnCode}`,
        nextColumnsTooltip: 'Show next columns',
        previousColumnsTooltip: 'Show previous columns',
        clearAssignmentTooltip: 'Clear column assignment',
        selectColumnTooltip: 'Select column for assignment',
        unselectColumnTooltip: 'Unselect column',
        dragTargetAreaCaption: 'Target fields',
        getDragTargetOptionalCaption: (field) => `${field} (optional)`,
        getDragTargetRequiredCaption: (field) => `${field} (required)`,
        dragTargetPlaceholder: 'Drag column here',
        getDragTargetAssignTooltip: (columnCode) => `Assign column ${columnCode}`,
        dragTargetClearTooltip: 'Clear column assignment',
        columnCardDummyHeader: 'Unassigned field',
        getColumnCardHeader: (code) => `Column ${code}`
    },
    progressStep: {
        stepSubtitle: 'Import',
        uploadMoreButton: 'Upload More',
        finishButton: 'Finish',
        statusError: 'Could not import',
        statusComplete: 'Complete',
        statusPending: 'Importing...',
        processedRowsLabel: 'Processed rows:'
    }
};
export const deDE = {
    general: {
        goToPreviousStepTooltip: 'Zum vorherigen Schritt'
    },
    fileStep: {
        initialDragDropPrompt: 'CSV-Datei auf dieses Feld ziehen, oder klicken um eine Datei auszuwählen',
        activeDragDropPrompt: 'CSV-Datei auf dieses Feld ziehen...',
        nextButton: 'Spalten auswählen',
        getImportError: (message) => `Fehler beim Import: ${message}`,
        getDataFormatError: (message) => `Bitte Datenformat überprüfen: ${message}`,
        goBackButton: 'Zurück',
        rawFileContentsHeading: 'Originaler Datei-Inhalt',
        previewImportHeading: 'Import-Vorschau',
        dataHasHeadersCheckbox: 'Mit Kopfzeile',
        previewLoadingStatus: 'Vorschau wird geladen...'
    },
    fieldsStep: {
        stepSubtitle: 'Spalten auswählen',
        requiredFieldsError: 'Bitte weise allen nicht optionalen Spalten einen Wert zu',
        nextButton: 'Importieren',
        dragSourceAreaCaption: 'Zu importierende Spalte',
        getDragSourcePageIndicator: (currentPage, pageCount) => `Seite ${currentPage} von ${pageCount}`,
        getDragSourceActiveStatus: (columnCode) => `Spalte ${columnCode} zuweisen`,
        nextColumnsTooltip: 'Nächste Spalten anzeigen',
        previousColumnsTooltip: 'Vorherige Spalten anzeigen',
        clearAssignmentTooltip: 'Zugewiesene Spalte entfernen',
        selectColumnTooltip: 'Spalte zum Zuweisen auswählen',
        unselectColumnTooltip: 'Spalte abwählen',
        dragTargetAreaCaption: 'Zielfelder',
        getDragTargetOptionalCaption: (field) => `${field} (optional)`,
        getDragTargetRequiredCaption: (field) => `${field} (erforderlich)`,
        dragTargetPlaceholder: 'Spalte hierher ziehen',
        getDragTargetAssignTooltip: (columnCode) => `Spalte ${columnCode} zuweisen`,
        dragTargetClearTooltip: 'Zugewiesene Spalte entfernen',
        columnCardDummyHeader: 'Nicht zugewiesenes Feld',
        getColumnCardHeader: (code) => `Spalte ${code}`
    },
    progressStep: {
        stepSubtitle: 'Importieren',
        uploadMoreButton: 'Weitere hochladen',
        finishButton: 'Abschließen',
        statusError: 'Konnte nicht importiert werden',
        statusComplete: 'Fertig',
        statusPending: 'Wird importiert...',
        processedRowsLabel: 'Verarbeitete Zeilen:'
    }
};
