import React, { useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useLocale } from '../../locale/LocaleContext';
import './FileSelector.scss';
export const FileSelector = ({ onSelected }) => {
    const onSelectedRef = useRef(onSelected);
    onSelectedRef.current = onSelected;
    const dropHandler = useCallback((acceptedFiles) => {
        // silently ignore if nothing to do
        if (acceptedFiles.length < 1) {
            return;
        }
        const file = acceptedFiles[0];
        onSelectedRef.current(file);
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: dropHandler
    });
    const l10n = useLocale('fileStep');
    return (React.createElement("div", Object.assign({ className: "CSVImporter_FileSelector", "data-active": !!isDragActive }, getRootProps()),
        React.createElement("input", Object.assign({}, getInputProps())),
        isDragActive ? (React.createElement("span", null, l10n.activeDragDropPrompt)) : (React.createElement("span", null, l10n.initialDragDropPrompt))));
};
